import React from "react";
import { withRouter } from 'react-router-dom';
import PatientService from "../services/PatientService";
import { Link } from 'react-router-dom';
import PatientHistory from "./PatientHistory";
import Loader from "../loader/Loader";

class PatientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      model: {
        id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        birthdate: "",
        medical_history_json: {},
        family_history_json: {}
      }
    };
  }

  componentDidMount() {
    if (!this.props.isNewPatient) {
      this.getModel();
    }else{
      let newModel = {
        id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        birthdate: "",
        medical_history_json: {},
        family_history_json: {}
      }
      import(`../data/medicalHistory.json`).then(response => {
        newModel.medical_history_json = response.default;
        import(`../data/familyHistory.json`).then(response => {
          newModel.family_history_json = response.default;
          this.setState({
            model: newModel, loader: false
          });
        })
          .catch(e => {
            console.log(e);
          });
      })
        .catch(e => {
          console.log(e);
        });
    }
  }

  getModel = () => {
    PatientService.get(this.props.match.params.patientId)
      .then(response => {
        console.log(response);
        this.setState({ model: response, loader: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  updateModel = () => {
    if (this.props.isNewPatient === true) {
      PatientService.store(this.state.model)
        .then(response => {
          this.props.history.push('/patient');
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      PatientService.update(this.props.match.params.patientId, this.state.model)
        .then(response => {
          this.props.history.push('/patient');
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  deleteModel = () => {
    PatientService.remove(this.props.match.params.patientId)
      .then(response => {
        this.props.history.push('/patient');
      })
      .catch(e => {
        console.log(e);
      });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      model: {
        ...this.state.model,
        [name]: value
      }
    });
  }

  onInputChange = (e, newTest) => {
    const nextModel = Object.assign({}, this.state.model);
    nextModel.medical_history_json.groups.forEach((group, groupKey) => {
      group.tests.forEach((test, testKey) => {
        if (test.name === newTest.name) {
          nextModel.medical_history_json.groups[groupKey].tests[testKey] = newTest;
        }
      });
    });

    this.setState({ model: nextModel });
  };

  render() {
    let model = this.state.model;
    return (
      this.state.loader ?
        <Loader />
        :
      <>
        <form>
          <div className="mb-3">
            <label className="form-label">Фамилия</label>
            <input
              className="form-control"
              name="last_name"
              type="text"
              value={model.last_name}
              onChange={this.handleInputChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Имя</label>
            <input
              className="form-control"
              name="first_name"
              type="text"
              value={model.first_name}
              onChange={this.handleInputChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Отчество</label>
            <input
              className="form-control"
              name="middle_name"
              type="text"
              value={model.middle_name}
              onChange={this.handleInputChange} />
          </div>
          <div className="mb-3">
            <label className="form-label">Дата рождения</label>
            <input
              className="form-control"
              name="birthdate"
              type="text"
              value={model.birthdate}
              onChange={this.handleInputChange} />
          </div>
          <h4>Сведения о семье</h4>
          <PatientHistory
            patientHistory={model.family_history_json}
            isEdit={true}
            onInputChange={this.onInputChange}
          />
          <h4>Анамнез</h4>
          <PatientHistory
            patientHistory={model.medical_history_json}
            isEdit={true}
            onInputChange={this.onInputChange}
          />
        </form>
        <div className="row gy-3 mt-5 mb-5 justify-content-end">
          <div className="col-6 col-md-2 d-grid">
            <Link className="btn btn-outline-primary" to={`/patient/view/${this.props.match.params.patientId}`}>Отмена</Link>
          </div>
          {
            !this.props.isNewPatient && <div className="col-6 col-md-2 d-grid">
              <button className="btn btn-outline-danger" onClick={this.deleteModel}>Удалить</button>
            </div>
          }
          <div className="col-6 col-md-2 d-grid">
            <button type="submit" className="btn btn-primary" onClick={this.updateModel}>Сохранить</button>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(PatientForm);