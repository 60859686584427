import React from 'react';
import DefaultTest from "./DefaultTest";

class SelectTest extends DefaultTest {
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        this.props.test.value = e.target.value;
        this.props.onInputChange(e, { newTest: this.props.test });
    };

    render() {
        var test = this.state.test;
        return (
            <>
                <div className="mb-3 row">
                    <label className="form-label col-sm-6">{test.title}{" "}<i>{test.description ? test.description : ""}</i></label>
                    <div className="col-sm-6">
                        <select 
                            name={test.name}
                            value={test.value}
                            required={true}
                            onChange={this.handleChange}
                            className="form-select">
                            {this._renderOptions(test.options)}
                        </select>
                    </div>
                </div>
            </>
        );
    }

}
export default SelectTest;