import React, { Component } from "react";
import TestList from "../tests/TestList";
import { withRouter } from 'react-router-dom';
import ErrorBoundary from "../tests/ErrorBoundary";

class SurveyConstructor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      inputData: {},
      formData: {
        groups: []
      },
    };
  }


  componentDidMount() {
    this.getInitialFormData();
  }


  getFormData = (e) => {
    this.setState({
      hasError: false
    });
    try {
      const jsonData = JSON.parse(e.target.value);
      this.setState({
        formData: jsonData
      });
      this.setState({
        inputData: this.state.formData
      });
    } catch (e) {
      this.setState({
        hasError: true
      });
    }
  };

  onInputChange = (e, state) => {
    const { name, value, groupName } = state;

    const nextInputData = Object.assign({}, this.state.inputData);
    nextInputData.groups.forEach((group, groupKey) => {
      if (group.name === groupName) {
        group.tests.forEach((test, testKey) => {
          if (test.name === name) {
            nextInputData.groups[groupKey].tests[testKey].value = value;
          }
        });
      }
    });

    this.setState({ inputData: nextInputData });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-6 border-end border-5">
          <h3 className="mb-3 text-primary">Исходная схема речевой карты</h3>
          <textarea
            id="sourceForm"
            onChange={this.getFormData}
            className="form-control"
            rows="40">
          </textarea>
        </div>
        <div className="col-12 col-md-6">
          <h3 className="mb-3 text-danger">Результат</h3>
          <form>{this.state.hasError ?
            <div className="alert alert-danger text-center" role="alert">
              <b>Что-то пошло не так! Ошибка генерации речевой карты!</b>
            </div>
            :
            <ErrorBoundary hasError={this.state.hasError}>
              <TestList
                formData={this.state.formData}
                onInputChange={this.onInputChange}
              />
            </ErrorBoundary>
          }
          </form>
        </div>
      </div>
    );
  }

  getInitialFormData = () => {
    var sourceForm = document.getElementById("sourceForm");
    sourceForm.value = `{
        "groups": [
            {
                "title": "Строение и подвижность артикуляторного аппарата",
                "name": "group1",
                "subgroups": [
                    {
                        "title": "Мимические движения",
                        "description": "Под счет 5 раз",
                        "tests": [
                            {
                                "title": "Поднять брови",
                                "name": "test1",
                                "type": "default"
                            },
                            {
                                "title": "Нахмурить брови",
                                "name": "test2",
                                "type": "default"
                            },
                            {
                                "title": "Прищурить глаза",
                                "name": "test3",
                                "type": "default"
                            },
                            {
                                "title": "Надуть-втянуть щеки",
                                "name": "test4",
                                "type": "default"
                            }
                        ]
                    },
                    {
                        "title": "Зубы",
                        "tests": [
                            {
                                "title": "Зубы",
                                "description": "(качественная характеристика)",
                                "name": "test5",
                                "type": "options",
                                "options": [
                                    {
                                        "title": "Норма"
                                    },
                                    {
                                        "title": "Редкие"
                                    },
                                    {
                                        "title": "Кривые"
                                    },
                                    {
                                        "title": "Вне челюстной дуги"
                                    },
                                    {
                                        "title": "Отсутствуют"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "title": "Язык",
                        "tests": [
                            {
                                "title": "Какой звук в конце слова?",
                                "description": "Кошка, автобус, дом",
                                "name": "test6",
                                "type": "default"
                            },
                            {
                                "title": "Узкий удержание 5 сек «жало»",
                                "name": "test7",
                                "type": "default"
                            },
                            {
                                "title": "Язык",
                                "description": "(качественная характеристика)",
                                "name": "test8",
                                "type": "options",
                                "options": [
                                    {
                                        "title": "Массивный"
                                    },
                                    {
                                        "title": "Географический"
                                    },
                                    {
                                        "title": "Маленький"
                                    },
                                    {
                                        "title": "Девиация кончика вправо-влево"
                                    },
                                    {
                                        "title": "Нарушение функции смыкания"
                                    },
                                    {
                                        "title": "Фебриллярное подергивание языка"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "title": "Познавательные процессы",
                "name": "group2",
                "subgroups": [
                    {
                        "title": "Счетные операции",
                        "tests": [
                            {
                                "title": "Счет прямой и обратный",
                                "name": "test9",
                                "type": "default"
                            },
                            {
                                "title": "Сосчитать сколько предметов нарисовано на картинке",
                                "name": "test10",
                                "image": "image1",
                                "type": "default"
                            },
                            {
                                "title": "Назвать по порядку предметы нарисованные на картинке",
                                "name": "test11",
                                "image": "image2",
                                "type": "default"
                            }
                        ]
                    },
                    {
                        "title": "Ориентировка в пространстве",
                        "tests": [
                            {
                                "title": "Высоко",
                                "name": "test12",
                                "type": "default"
                            },
                            {
                                "title": "Низко",
                                "name": "test13",
                                "type": "default"
                            },
                            {
                                "title": "Далеко",
                                "name": "test14",
                                "type": "default"
                            },
                            {
                                "title": "Близко",
                                "name": "test15",
                                "type": "default"
                            },
                            {
                                "title": "Слева",
                                "name": "test16",
                                "type": "default"
                            },
                            {
                                "title": "Справа",
                                "name": "test17",
                                "type": "default"
                            }
                        ]
                    },
                    {
                        "title": "Ориентировка во времени",
                        "tests": [
                            {
                                "title": "Какое время года нарисовано на картинке?",
                                "image": "image3",
                                "name": "test18",
                                "type": "default"
                            },
                            {
                                "title": "Назвать дни недели",
                                "name": "test19",
                                "type": "default"
                            }
                        ]
                    },
                    {
                        "tests": [
                            {
                                "title": "Общий комментарий к выполнению группы заданий (заполнить, если нужно)",
                                "name": "test20",
                                "type": "textarea"
                            }
                        ]
                    }
                ]
            },
            {
                "title": "Воспроизведение звуко-слоговой структуры слова",
                "name": "group3",
                "subgroups": [
                    {
                        "title": "Речевой материал",
                        "tests": [
                            {
                                "title": "На перекрёстке стоит регулировщик",
                                "name": "test21",
                                "audio": "audio1",
                                "type": "default"
                            },
                            {
                                "title": "Экскурсию ведёт экскурсовод",
                                "name": "test22",
                                "audio": "audio2",
                                "type": "default"
                            }
                        ]
                    }
                ]
            },
            {
                "title": "Фонематическое восприятие (повтори, покажи)",
                "name": "group4",
                "subgroups": [
                    {
                        "title": "Речевой материал",
                        "tests": [
                            {
                                "title": "Мишка – мышка",
                                "name": "test23",
                                "audio": "audio5",
                                "type": "default"
                            }
                        ]
                    }
                ]
            },
            {
                "title": "Состояние звукопроизношения (качественная характеристика)",
                "name": "group5",
                "subgroups": [
                    {
                        "title": "Б-М-П",
                        "tests": [
                            {
                                "title": "Бант-мыло-паук",
                                "name": "test24",
                                "audio": "audio3",
                                "type": "default"
                            }
                        ]
                    },
                    {
                        "title": "В-Ф",
                        "tests": [
                            {
                                "title": "Вата-фокус",
                                "name": "test25",
                                "audio": "audio4",
                                "type": "default"
                            }
                        ]
                    }
                ]
            }
        ]
    }`;

    this.setState({ formData: JSON.parse(sourceForm.value) });

  };

}

export default withRouter(SurveyConstructor);
