export default class PointsProcessor {

    static Process(survey) {

        let groupNumber = Number(0);
        let perfomGroupNumber = Number(0);
        let groupSum = Number(0);

        //!!!
        let isSpell = false;

        survey.groups.forEach((group, groupKey) => {

            let testNumber = Number(0);
            let perfomTestNumber = Number(0);
            let sum = Number(0);           
            

            group.subgroups.forEach((subgroup, subgroupKey) => {
                subgroup.tests.forEach((test, testKey) => {

                    if (test.type === 'spell_repeat' ||
                        test.type === 'spell_picture') {
                        //!!!
                        isSpell = true;
                    }

                    if (test.type === 'default' ||
                        test.type === 'sound_reproduction_letter' ||
                        test.type === 'sound_reproduction_word' ||
                        test.type === 'sound_reproduction_phrase' ||
                        test.type === 'spell_repeat' ||
                        test.type === 'spell_picture'
                    ) {
                        testNumber++;
                        if (test.value !== undefined) {
                            perfomTestNumber++;
                            if (test.points !== undefined) {
                                if (Number(test.points) > 0) {
                                    sum = sum + Number(test.points);
                                }
                            }
                        }
                    }
                });
            });
            survey.groups[groupKey].sum = sum;
            survey.groups[groupKey].testNumber = testNumber;
            survey.groups[groupKey].perfomTestNumber = perfomTestNumber;

            
            survey.groups[groupKey].result = isSpell?sum:sum / testNumber;

            if (survey.groups[groupKey].testNumber > 0) {
                if (survey.groups[groupKey].perfomTestNumber === survey.groups[groupKey].testNumber) {
                    perfomGroupNumber++;
                }
                groupSum = groupSum + Number(survey.groups[groupKey].result);
            }

            if (survey.groups[groupKey].testNumber > 0) {
                groupNumber++;
            }
        });

        survey.groupNumber = groupNumber;
        survey.perfomGroupNumber = perfomGroupNumber;
        survey.groupSum = groupSum;
        survey.totalResult = isSpell?groupSum:groupSum / groupNumber;
    };
}