import React, { Component } from "react";
import ResultSoundReproductionChart from "./ResultSoundReproductionChart";

export default class ResultSoundReproduction extends Component {

  render() {

    let resultsByDeviation = this.props.result.resultsByDeviation;
    let resultsByLetters = this.props.result.resultsByLetters;
    return (
      <>
        <div className="fs-2 mb-4">
          Состояние звукопроизношения
        </div>

        {resultsByLetters !== undefined ? <><div className="row">
          <div className="col-12">
          <div className="fs-5 mb-2">График (распределение по звукам)</div>
            <ResultSoundReproductionChart
              letters={resultsByLetters.letters}
            />
          </div>
        </div>
        </>: ""
        }
        <div className="row">
          <div className="col-12 col-lg-6">
            {resultsByDeviation !== undefined ? <>
              <div className="fs-5 mb-2">{resultsByDeviation.title}</div>
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" width="10%"></th>
                      <th scope="col" width="30%" className="text-center table-light">Изолированно</th>
                      <th scope="col" width="30%" className="text-center table-light">В словах</th>
                      <th scope="col" width="30%" className="text-center table-light">Во фразах</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resultsByDeviation.deviations.map((deviation, deviationKey) => {
                      return (
                        <tr key={deviationKey}>
                          <td className="table-light"><b>{deviation.title}</b></td>
                          {
                            deviation.places.map((place, placeKey) => {
                              return (
                                <td className={place.letters.length > 0 ? `text-center table-warning` : `text-center`} key={placeKey}>
                                  {
                                    place.letters.length > 0 ?
                                      place.letters.map((letter, letterKey) => {
                                        return (
                                          <b className="text-danger fs-6" key={letterKey}>{letter}&nbsp;</b>
                                        )
                                      }) : "-"
                                  }
                                </td>
                              )
                            })
                          }
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </>
              : ""}
          </div>
          <div className="col-12 col-lg-6">
            {resultsByLetters !== undefined ? <>
              <div className="fs-5 mb-2">{resultsByLetters.title}</div>
              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="col" className="text-center">Звук</th>
                    <th scope="col">Особенности произношения</th>
                    <th scope="col" className="text-center">Баллы</th>
                  </tr>
                </thead>
                <tbody>
                  {resultsByLetters.letters.map((letter, letterKey) => {
                    return (
                      <tr key={letterKey}>
                        <td className="text-center"><b className="text-danger fs-6">{letter.title}</b></td>
                        <td>{
                          letter.description.map((element, key) => {
                            return (
                              <div key={key}>
                                {element}
                              </div>
                            )
                          })
                        }</td>
                        <td className="text-center"> {letter.points}</td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </>
              : ""}
          </div>
        </div>
      </>
    );
  }
}