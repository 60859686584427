import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import PatientSurveyService from "../services/PatientSurveyService";
import ResultSoundReproduction from "../result/ResultSoundReproduction";
import ResultSpell from "../result/ResultSpell";
import ResultProcessorSoundReproduction from "../result/ResultProcessorSoundReproduction";
import ResultProcessorSpell from "../result/ResultProcessorSpell";
import Loader from "../loader/Loader";
import { Link } from 'react-router-dom';

class PatientResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      result: { "reports": [] }
    };
  }

  getFormData = () => {
    PatientSurveyService.get(this.props.match.params.surveyId)
      .then(response => {
        const survey = response.data.data.survey_json;


        if (survey.type === "sound_reproduction") {
          this.setState({
            result: ResultProcessorSoundReproduction.process(survey), loader: false
          });
        }
        if (survey.type === "spell") {
          this.setState({
            result: ResultProcessorSpell.process(survey), loader: false
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  componentDidMount() {
    this.getFormData();
  }

  render() {
    if (this.state.loader)
    return (<Loader />)
        
    if (this.state.result.type === "sound_reproduction") {
      return (
        <>
        <div>
          <ResultSoundReproduction
            result={this.state.result}
            patientId={this.props.match.params.patientId}
            surveyId={this.props.match.params.surveyId}
          />
        </div>
        <div className="row">
            <div className="col-12">
              <div className="row gy-3 mt-5 mb-5 justify-content-start">
                <div className="col-6 col-md-2 d-grid">
                  <Link className="btn btn-outline-primary" to={`/patient/${this.props.match.params.patientId}/survey/${this.props.match.params.surveyId}`}>Назад</Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    if (this.state.result.type === "spell") {
      return (
        <>
        <div>
          <ResultSpell
            result={this.state.result}
            patientId={this.props.match.params.patientId}
            surveyId={this.props.match.params.surveyId}
          />
        </div>
        <div className="row">
            <div className="col-12">
              <div className="row gy-3 mt-5 mb-5 justify-content-start">
                <div className="col-6 col-md-2 d-grid">
                  <Link className="btn btn-outline-primary" to={`/patient/${this.props.match.params.patientId}/survey/${this.props.match.params.surveyId}`}>Назад</Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return (
      <div>
        <h2 className="text-danger">Неизвестный тип результата обследования</h2>
      </div>
    );
  }
}

export default withRouter(PatientResult);
