import React, { Component } from "react";
import { Link } from 'react-router-dom';
import ResultProcessorSpell from "../result/ResultProcessorSpell";

export default class ResultSoundReproduction extends Component {

  render() {

    console.log(this.props);
    let reportDeviations = this.props.result.reports[0];

    return (
      <>
        <div className="fs-4 mb-3">
          Состояние сформированности слоговой структуры
        </div>
        <div className="row mb-3">
          <div className="col-6 col-lg-6">
            Уровень успешности: <span className="fs-3">{ResultProcessorSpell._getBadge(this.props.result.totalResult)}</span>
          </div>
          <div className="col-6 col-lg-6 text-end">
            <Link className="btn btn-lg btn-outline-success mr-1" to={`/patient/${this.props.patientId}/result/${this.props.surveyId}/correction-route/${this.props.result.correctionRouteId}`}>Предлагаемый коррекционный маршрут <i className="fas fa-arrow-right"></i></Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {reportDeviations !== undefined ? <>
              <h3 className="pb-2">{reportDeviations.title}</h3>
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" width="40%">Вид</th>
                      <th scope="col" width="60%" className="text-center table-light">Результат</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDeviations.deviations.map((deviation, deviationKey) => {
                      return (
                        <tr key={deviationKey}>
                          <td className="table-light"><b>{deviation.title}</b></td>
                          <td>
                            {
                              deviation.words.map((word, keyWord) => {
                                return (
                                  <div key={keyWord}>
                                    <b className="text-danger fs-6">{word.pronunciationVariant ? word.pronunciationVariant : "-"}</b>
                                    &nbsp;({word.title})<br />
                                  </div>
                                )
                              })
                            }
                          </td>
                        </tr>
                      )
                    })
                    }
                  </tbody>
                </table>
              </div>
            </>
              : ""}
          </div>
        </div>
      </>
    );
  }
}

