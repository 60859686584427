import React from "react";
import { Link } from 'react-router-dom';

class PatientSurveyNewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { surveyId: "articulatory_apparatus" };
  }

  handleChange = (event) => {
    this.setState({ surveyId: event.target.value });
  }

  render() {
    return (
      <div>
        <div className="row mb-3">
          <div className="input-group">
            <select className="form-select" value={this.state.surveyId} onChange={this.handleChange} >
              <option value="articulatory_apparatus">Анатомическое строение артикуляционного аппарата</option>
              <option value="sound_reproduction">Состояние звукопроизношения</option>
              <option value="spell">Сформированность слоговой структуры слова</option>
            </select>
            <Link
              to={{
                pathname: `/patient/${this.props.patientId}/new-survey/${this.state.surveyId}`
              }} 
              className="btn btn-primary">Новое обследование</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientSurveyNewButton;