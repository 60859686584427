import React, { Component } from "react";
import PatientForm from "./PatientForm";
import PatientList from "./PatientList";
import PatientView from "./PatientView";
import { Route, Switch } from 'react-router-dom';

class Patients extends Component {
  
  render() {
    return (
      <Switch>
        <Route exact path="/patient">
          <PatientList />
        </Route>
        <Route path="/patient/edit/:patientId">
          <PatientForm />
        </Route>
        <Route path="/patient/new-patient">
          <PatientForm isNewPatient={true}/>
        </Route>
        <Route path="/patient/view/:patientId">
          <PatientView />
        </Route>
        <Route path="/patient/:patientId/new-survey">
          <PatientView withSurvey={true} isNewSurvey={true}/>
        </Route>
        <Route path="/patient/:patientId/survey/:surveyId">
          <PatientView withSurvey={true} isNewSurvey={false}/>
        </Route>
        <Route path="/patient/:patientId/result/:surveyId/correction-route/:correctionRouteId">
          <PatientView withCorrectionRoute={true}/>
        </Route>
        <Route path="/patient/:patientId/result/:surveyId">
          <PatientView withResult={true}/>
        </Route>
      </Switch>
    );
  }
}

export default Patients;
