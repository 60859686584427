export default class ResultProcessorSpell {

    static resultsByDeviation = {
        "deviations": [
            {
                "key": "quantity_violation",
                "title": "Нарушения количества слогов",
                "words": []
            },
            {
                "key": "sequence_violation",
                "title": "Нарушение последовательности слогов в слове",
                "words": []
            },
            {
                "key": "distortion_single_syllable",
                "title": "Искажение структуры отдельного слога",
                "words": []
            },
            {
                "key": "anticipations",
                "title": "Антиципации",
                "words": []
            },
            {
                "key": "perseverations",
                "title": "Персеверации",
                "words": []
            },
            {
                "key": "contamination",
                "title": "Контаминации",
                "words": []
            }]
    };

    static process(survey) {

        this.resultsByDeviation.deviations.forEach((deviation) => {
            deviation.words = [];
        });

        survey.groups.forEach((group, groupKey) => {
            group.subgroups.forEach((subgroup, subgroupKey) => {
                subgroup.tests.forEach((test, testKey) => {
                    this._setResultByDeviation(test);
                });
            });
        });

        let result = { "type": "spell", "totalResult": survey.totalResult, "totalLevel": this._getLevel(survey.totalResult), "correctionRouteId": this._getCorrectionRouteId(survey.totalResult), "reports": [this.resultsByDeviation, this.resultsByLetters] };
        return result;
    }

    static _getLevel(totalResult) {
        if (totalResult <= 9) {
            return "Низкий";
        }
        if (totalResult > 9 && totalResult <= 19) {
            return "Средний";
        }
        if (totalResult > 19) {
            return "Высокий";
        }
    }

    static _getBadge(totalResult) {
        if (totalResult <= 9) {
            return <><span className="badge bg-danger">{Number(totalResult).toFixed(1)} {ResultProcessorSpell._getLevel(totalResult)}</span></>;
        }
        if (totalResult > 9 && totalResult <= 19) {
            return <><span className="badge bg-warning text-dark">{Number(totalResult).toFixed(1)} {ResultProcessorSpell._getLevel(totalResult)}</span></>;
        }
        if (totalResult > 19) {
            return <><span className="badge bg-success">{Number(totalResult).toFixed(1)} {ResultProcessorSpell._getLevel(totalResult)}</span></>;
        }
    }

    static _getCorrectionRouteId(totalResult) {
        if (totalResult <= 9) {
            return 3;
        }
        if (totalResult > 9 && totalResult <= 19) {
            return 2;
        }
        if (totalResult > 19) {
            return 1;
        }
    }

    static _setResultByDeviation(test) {
        this.resultsByDeviation.deviations.forEach(deviation => {
            test.spellGroups.forEach(spellGroup => {
                spellGroup.words.map(word => {
                    if (word.typeOfDistortion === deviation.key) {
                        deviation.words.push({ "title": word.title, "pronunciationVariant": word.pronunciationVariant });
                    }
                });
            });
        });
    }
}
