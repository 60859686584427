import http from "../../http-common";

const getAll = patientId => {  
  return http.get(`/patient/${patientId}/survey`);
};

const get = id => {
  return http.get(`/survey/${id}`);
};

const store = (patientId, data) => {  
  return http.post(`/patient/${patientId}/survey`, data);
};

const update = (id, data) => {
  return http.put(`/survey/${id}`, data);
};

const destroy = id => {
  return http.delete(`/survey/${id}`);
};

export default {
  getAll,
  get,
  store,
  update,
  destroy,
};