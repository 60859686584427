import http from "../../http-common";

const getAll = () => {
  return http.get("/patient");
};

const get = id => {

  return new Promise(function (resolve, reject) {
    http.get(`/patient/${id}`).then(response => {
      let model = response.data.data;
      if (model.medical_history_json === "{}" || model.family_history_json === "{}") {
        import(`../data/medicalHistory.json`).then(response => {
          model.medical_history_json = response.default;
          import(`../data/familyHistory.json`).then(response => {
            model.family_history_json = response.default;
            resolve(model);
          })
            .catch(e => {
              reject(new Error(e));
            });
        })
          .catch(e => {
            reject(new Error(e));
          });
      } else {
        resolve(model);
      }
    })
      .catch(e => {
        reject(new Error(e));
      });
  });
};

const store = data => {
  return http.post("/patient", data);
};

const update = (id, data) => {
  return http.put(`/patient/${id}`, data);
};

const remove = id => {
  return http.delete(`/patient/${id}`);
};

const removeAll = () => {
  return http.delete(`/patient`);
};

const findByTitle = title => {
  return http.get(`/patient?title=${title}`);
};

export default {
  getAll,
  get,
  store,
  update,
  remove,
  removeAll,
  findByTitle
};