import React, { Component } from "react";
import PatientSurveyService from "../services/PatientSurveyService";
import { Link } from 'react-router-dom';
import PatientSurveyNewButton from "./PatientSurveyNewButton";
import Loader from "../loader/Loader";
import ResultProcessorSpell from "../result/ResultProcessorSpell";

class ExaminationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      models: []
    };
  }

  componentDidMount() {
    this.getModels()
  }

  getModels = () => {
    PatientSurveyService.getAll(this.props.patientId)
      .then(response => {
        this.setState({ models: response.data.data, loader: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  renderPoints = (survey_json) => {

    if (survey_json.totalResult) {
      if (survey_json.type === "spell") {        
        return ResultProcessorSpell._getBadge(survey_json.totalResult);
      } else {
        return <><span className="badge bg-secondary">{Number(survey_json.totalResult).toFixed(1)}</span></>;
      }
    }
  }

  render() {
    return (
      this.state.loader ?
        <Loader />
        :
        <>
          <PatientSurveyNewButton patientId={this.props.patientId} />
          <table className="table">
            <thead>
              <tr>
                <th style={{ width: "160px" }}>Дата</th>
                <th>Тип</th>
                <th>Результат</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.models.map((model, key) => {
                const { id, created_at, survey_json } = model;
                return (
                  <tr key={key}>
                    <td>{created_at}</td>
                    <td>{survey_json.title}</td>
                    <td>{this.renderPoints(survey_json)}</td>
                    <td style={{ textAlign: "right" }}>
                      <Link to={`/patient/${this.props.patientId}/survey/${id}`} className="btn btn-outline-primary btn-sm mr-1">Просмотр обследования</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
    );
  }
}

export default ExaminationList;
