import React, { Component } from "react";
import TestGroup from "./TestGroup";
import { Link } from 'react-router-dom';

class TestList extends Component {

  render() {
    const formData = this.props.formData;
    const percent = formData.perfomGroupNumber / formData.groupNumber * 100;
    return (
      <>
        <div className="fs-4 mb-3">
          {formData.title} {this.props.isNewSurvey ? "":<><span className="fs-6 text-muted">(Обследование проведено: {this.props.createdAt})</span></>}
        </div>
        {formData.groups.map(group => {
          return (
            <TestGroup
              key={group.title}
              type={formData.type}
              group={group}
              onInputChange={this.props.onInputChange}
            />
          );
        })}
        {formData.groupNumber ?
          <div className="border p-3 mb-3">
            <div className="progress mt-2" style={{ height: 3 }}>
              <div className={`progress-bar ${percent === 100 ? 'bg-success' : 'bg-danger'}`} style={{ width: percent + '%' }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <div className="row">
              <div className="col-12 col-md-9">
                <h2>
                  Общий итог баллов:
                </h2>
              </div>
              <div className={`col-12 col-md-3 text-end ${percent === 100 ? 'text-success' : 'text-danger'}`}>
                <h1>
                  {formData.totalResult ? Number(formData.totalResult).toFixed(1) : ''}
                </h1>
                <div className="text-muted">
                  <br />
                  Всего групп тестов: {formData.groupNumber}
                  <br />
                  Пройдено групп тестов: {formData.perfomGroupNumber}
                  <br />
                  {percent === 100 && !this.props.isNewSurvey ? <Link className="btn btn-lg btn-success mt-3" to={`/patient/${this.props.patientId}/result/${this.props.surveyId}`}>Результат</Link> : ""}
                </div>
              </div>
            </div>
          </div>
          :
          ''
        }
      </>
    );
  }
}

export default TestList;
