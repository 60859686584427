import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  Route,
  Redirect,
  useRouteMatch
} from "react-router-dom";
import SurveyConstructor from "./components/survey-constructor/SurveyConstructor";
import Patients from "./components/patients/Patients";

export default function App() {

  useEffect(() => {
    document.title = "Logopedia - автоматизированные речевые карты для логопеда"
  }, []);

  return (
    <>
      <Router>
        <nav className="navbar navbar-expand-lg navbar navbar-dark bg-primary">
          <div className="container">
            <a className="navbar-brand" href="/">Logopedia</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/patient"
                    label="Обследуемые"
                  />
                </li>
                <li className="nav-item">
                <ActiveLink
                    activeOnlyWhenExact={false}
                    to="/survey/constructor"
                    label="Конструктор речевых карт"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container mt-3">
              <Switch>
                <Route path="/patient">
                  <Patients />
                </Route>
                <Route path="/survey/constructor">
                  <SurveyConstructor />
                </Route>
                <Redirect from="*" to="/patient" />
              </Switch>
        </div>
      </Router>
    </>
  );
}

function ActiveLink({ label, to, activeOnlyWhenExact }) {
  let match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  });

  return (
    <Link className={match ? "nav-link active" : "nav-link"} to={to}>{label}</Link>
  );
}