import React, { Component } from "react";
import Test from "../tests/Test";

class PatientHistory extends Component {

  render() {
    const patientHistory = this.props.patientHistory;
    const isEdit = this.props.isEdit
    return (
      <>        
        {patientHistory!== undefined && patientHistory.groups!== undefined?patientHistory.groups.map((group, key) => {
          return (
            <div className = "border p-3 mb-3" key={key}>
              <div className="row">
                {group.title &&
                  <div className="col-12 col-md-2">
                    <h6>
                      {group.title}
                    </h6>
                    <div className="pb-3"><i>{group.description}</i></div>
                  </div>
                }
                <div className={group.title  ? `col-12 col-md-10 border-start` : `col-12 col-md-12`}>
                  {group.tests.map((test) => {
                    return (
                      <Test
                        key={test.name}
                        test={test}
                        onInputChange={this.props.onInputChange}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )
        })
        :
        <span className="text-muted">(не указаны)</span>
        }
      </>
    )
  }
}

export default PatientHistory;
