import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: this.props.hasError };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    if (this.state.hasError) {
      return <div className="alert alert-danger text-center" role="alert">
        <b>Что-то пошло не так! Ошибка генерации речевой карты!</b>
      </div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;