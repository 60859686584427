export default class ResultProcessorSoundReproduction {

    static resultsByDeviation = {
        "title": "По отклонениям",
        "type": "sound_reproduction_deviations",
        "deviations": [
            {
                "key": "not_pronounce",
                "title": "Пропускает",
                "places": [
                    {
                        "key": "sound_reproduction_letter",
                        "title": "Изолированно",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_word",
                        "title": "В словах",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_phrase",
                        "title": "Во фразах",
                        "letters": []
                    }
                ]
            },
            {
                "key": "distorts",
                "title": "Искажает",
                "places": [
                    {
                        "key": "sound_reproduction_letter",
                        "title": "Изолированно",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_word",
                        "title": "В словах",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_phrase",
                        "title": "Во фразах",
                        "letters": []
                    }
                ]
            },
            {
                "key": "replace",
                "title": "Заменяет",
                "places": [
                    {
                        "key": "sound_reproduction_letter",
                        "title": "Изолированно",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_word",
                        "title": "В словах",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_phrase",
                        "title": "Во фразах",
                        "letters": []
                    }
                ]
            },
            {
                "key": "deafen",
                "title": "Оглушает",
                "places": [
                    {
                        "key": "sound_reproduction_letter",
                        "title": "Изолированно",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_word",
                        "title": "В словах",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_phrase",
                        "title": "Во фразах",
                        "letters": []
                    }
                ]
            },
            {
                "key": "soften",
                "title": "Смягчает",
                "places": [
                    {
                        "key": "sound_reproduction_letter",
                        "title": "Изолированно",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_word",
                        "title": "В словах",
                        "letters": []
                    },
                    {
                        "key": "sound_reproduction_phrase",
                        "title": "Во фразах",
                        "letters": []
                    }
                ]
            }]
    };

    static resultsByLetters = {
        "title": "По звукам",
        "type": "sound_reproduction_letters",
        "letters": []
    };

    static process(survey) {
        survey.groups.forEach((group, groupKey) => {
            group.subgroups.forEach((subgroup, subgroupKey) => {
                subgroup.tests.forEach((test, testKey) => {
                    this._setResultByDeviation(test);
                    this._setResultByLetter(test);
                });
            });
        });

        let result = { "type": "sound_reproduction", 
        "resultsByDeviation": this.resultsByDeviation, 
        "resultsByLetters": this.resultsByLetters};
        return result;
    }

    static _setResultByDeviation(test) {
        this.resultsByDeviation.deviations.forEach(deviation => {
            if (deviation.key === test.value) {
                deviation.places.forEach(place => {
                    if (place.key === test.type) {
                        if (!place.letters.includes(test.letter)) {
                            place.letters.push(test.letter);
                        }
                    }
                });
            }
            if (deviation.key === "not_pronounce" && test.value === "not_auto" && test.type === "sound_reproduction_phrase") {
                deviation.places.forEach(place => {
                    if (place.key === test.type) {
                        if (!place.letters.includes(test.letter)) {
                            place.letters.push(test.letter);
                        }
                    }
                });
            }
        });
    }

    static _setResultByLetter(test) {
        if (test.value !== "norm") {
            let isLetterExist = false;
            this.resultsByLetters.letters.forEach((letter, letterKey) => {

                if (letter.title === test.letter) {
                    isLetterExist = true;

                    this.resultsByLetters.letters[letterKey].testNumber = letter.testNumber + 1;
                    this.resultsByLetters.letters[letterKey].sumPoints = letter.sumPoints + test.points;
                    this.resultsByLetters.letters[letterKey].points = Number(this.resultsByLetters.letters[letterKey].sumPoints / this.resultsByLetters.letters[letterKey].testNumber).toFixed(1);
                    if (!letter.description.includes(this._getLetterDescription(test.value))) {
                        this.resultsByLetters.letters[letterKey].description.push(this._getLetterDescription(test.value));
                    }
                }
            });
            if (!isLetterExist) {
                this.resultsByLetters.letters.push({
                    "title": test.letter,
                    "description": [this._getLetterDescription(test.value)],
                    "testNumber": 1,
                    "sumPoints": test.points,
                    "points": test.points
                })
            }
        }
    }

    static _getLetterDescription(type) {
        if (type === "not_pronounce") {
            return "Пропускает";
        }
        if (type === "distorts") {
            return "Искажает";
        }
        if (type === "replace") {
            return "Заменяет";
        }
        if (type === "deafen") {
            return "Оглушает";
        }
        if (type === "soften") {
            return "Смягчает";
        }
        if (type === "auto") {
            return "Автоматизирован";
        }
        if (type === "not_auto") {
            return "Неавтоматизирован";
        }
        new Error("Неизвестный тип отклонения звукопроизношения!");
    }
}
