import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from "../loader/Loader";

class PatientCorrectionRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      correctionRoute: {}
    };
  }

  componentDidMount() {
    this._getData();
  }

  render() {
    const correctionRoute = this.state.correctionRoute;
    return (
      this.state.loader ?
        <Loader />
        :
        <>
          <div className="row">
            <div className="col-12">
              <h2 className="pb-3">
                {correctionRoute.title} <span className="badge bg-primary">{correctionRoute.number}</span>
              </h2>
              <div className="row">
                {
                  correctionRoute.groups.map((group, groupKey) => {
                    return (
                      <>
                        <div className="col-4" key={groupKey}>
                          <div className="row m-0 mb-2 border border-primary bg-primary text-light rounded-3">
                            <div className="col-12 p-3">
                              <h5>{group.title}</h5>
                            </div>
                          </div>
                          {
                            group.subgroups.map((subgroup, subgroupKey) => {
                              return (
                                <>
                                  <div className="row m-0 mb-2 border border-secondary rounded-3">
                                    <div className="col-12 p-3">
                                      {subgroup.title}
                                      {subgroup.description}
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                          <div className="accordion" id={"accordion" + groupKey}>
                            {
                              <>
                                <div className="accordion-item">
                                  <h2 className="accordion-header " id={"heading" + groupKey}>
                                    <button className="bg-light accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + groupKey} aria-expanded="true" aria-controls={"collapse" + groupKey}>
                                      <span className="fs-5 fw-bold">Упражнения</span>
                                    </button>
                                  </h2>
                                  <div id={"collapse" + groupKey} className="accordion-collapse collapse" aria-labelledby={"heading" + groupKey} data-bs-parent={"#accordion" + groupKey}>
                                    <div className="accordion-body">
                                      <small>
                                        {
                                          group.description ? <><div className="pb-1 fw-bold">Задачи:</div><div className="alert alert-warning" role="alert">{group.description}</div><div className="pt-2 pb-1 fw-bold">Упражнения:</div></> : ""
                                        }
                                        {
                                          group.exercises.map((exercise, exerciseKey) => {
                                            return (
                                              <>
                                                <div className="row mb-2" key={exerciseKey}>
                                                  <div className="col-12">
                                                    <i>
                                                      <u  className="bg-warning fw-bold" >{exercise.title}</u><br />
                                                      {
                                                        exercise.order.map((item, itemKey) => {
                                                          return (
                                                            <>
                                                              {item}<br />
                                                            </>
                                                          )
                                                        })
                                                      }
                                                    </i>
                                                  </div>
                                                </div>
                                              </>
                                            )
                                          })
                                        }
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </>
                            }
                          </div>
                        </div>
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row gy-3 mt-5 mb-5 justify-content-start">
                <div className="col-6 col-md-2 d-grid">
                  <Link className="btn btn-outline-primary" to={`/patient/${this.props.match.params.patientId}/result/${this.props.match.params.surveyId}`}>Назад</Link>
                </div>
              </div>
            </div>
          </div>
        </>
    );
  }

  _getData = () => {
    import(`../data/correction_route${this.props.match.params.correctionRouteId}.json`).then(response => {
      const nextCorrectionRoute = Object.assign({}, response.default);
      this.setState({
        correctionRoute: nextCorrectionRoute, loader: false
      });
    })
      .catch(e => {
        console.log(e);
      });

  };
}

export default withRouter(PatientCorrectionRoute);
