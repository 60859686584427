import React from 'react';
import ImageFile from "./ImageFile";
import AudioFile from "./AudioFile";

export default class DefaultTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            test: props.test
        };
    }

    handleOptionsChange = (e) => {
        let points = 0;
        this._getOptions(this.state.test).forEach(option => {
            if (e.target.value === option.value) {
                points = option.points;
            }
        });

        this.props.test.value = e.target.value;
        this.props.test.points = points;

        this.props.onInputChange(e, { newTest: this.props.test });
    };


    render() {
        var test = this.state.test;
        return (
            <>
                <div className="mb-3 row">
                    <label htmlFor="" className="form-label col-sm-9">{test.title}{" "}<i>{test.description ? test.description : ""}</i>
                        {test.image &&
                            <ImageFile image={test.image} title={test.title} />
                        }
                        {test.audio &&
                            <AudioFile audio={test.audio} title={test.title} />
                        }
                    </label>
                    <div className="col-sm-3">
                        {
                            this._renderInputRadio(test)
                        }
                    </div>
                </div>
            </>
        );
    }

    _renderInputRadio = (test) => {
        return (
            <>
                {this._getOptions(test).map((option, key) => {
                    return <div className="form-check form-check-inline" key={key}>
                        <input
                            type="radio"
                            name={test.name}
                            value={option.value}
                            points={option.points}
                            className="form-check-input"
                            checked={test.value === option.value}
                            onChange={this.handleOptionsChange}
                        /><label className="form-check-label" htmlFor="">
                            {option.title}
                        </label>
                    </div>
                })
                }
            </>
        )
    }

    _renderOptions = (options) => {
        return [
            { value: null, label: null },
            ...options
        ].map((option, i) => {
            const { value, title} = option;
            return <option key={i} value={value}>{title}</option>
        });
    };   

    _getOptions = (test) => {
        if (test.options) {
            return test.options;
        }
        return [
            {
                title: "0",
                value: "0",
                points: 0
            },
            {
                title: "1",
                value: "1",
                points: 1
            },
            {
                title: "2",
                value: "2",
                points: 2
            }
        ];
    }

}
