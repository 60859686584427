import React from "react";

class Loader extends React.Component {

    render() {
        return(
        <div className="row mt-3">
            <div className="col-12 text-center">
                <div className="spinner-grow text-primary m-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-primary m-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="spinner-grow text-primary m-1" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        )
    }

}

export default Loader;