import React, { Component } from "react";
import PatientService from "../services/PatientService";
import { Link } from 'react-router-dom';

class PatientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      models: []
    };
  }

  componentDidMount() {
    this.getModels()
  }

  getModels = () => {
    PatientService.getAll()
      .then(response => {
        this.setState({ models: response.data.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  render() {
    return (
      <>
        <div className="row align-items-center mb-3">
          <div className="col-6">
            <h2>Список обследуемых</h2>
          </div>
          <div className="col-6 text-end">
            <Link
              to={`/patient/new-patient`}
              className="btn btn-outline-primary btn-sm mr-1">
              Добавить нового обследуемого
               </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table className="table table-hover table-sm">
              <thead>
                <tr>
                  <th>ФИО</th>
                  <th style={{ width: "200px", textAlign: "center" }}>Дата рождения</th>
                  <th style={{ width: "200px", textAlign: "center" }} className="d-none d-md-table-cell">Возраст<br />(полных лет)</th>
                  <th style={{ width: "150px" }}></th>
                </tr>
              </thead>
              <tbody>
                {this.state.models.map((model, key) => {
                  const { id, first_name, last_name, birthdate } = model;
                  return (
                    <tr key={key}>
                      <td>{` ${last_name} ${first_name}`}</td>
                      <td style={{ textAlign: "center" }}>{birthdate}</td>
                      <td style={{ textAlign: "center" }} className="d-none d-md-table-cell">{this.getAge(birthdate)}</td>
                      <td style={{ textAlign: "right" }}>
                        <Link to={`/patient/view/${id}`} className="btn btn-outline-primary btn-sm mr-1">Просмотр</Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default PatientList;
