import React, { Component } from "react";

import SoundReproductionTest from "./SoundReproductionTest";
import SpellTest from "./SpellTest";
import DefaultTest from "./DefaultTest";
import TextareaTest from "./TextareaTest";
import SelectTest from "./SelectTest";

class Test extends Component {

  render() {
    const test = this.props.test;
    return (
      this._createTest(test)
    );
  }

  _createTest = (test) => {
    switch (test.type) {
      case 'textarea': return this._createTextareaTest(test);
      case 'options': return this._createOptionsTest(test);
      case 'default': return this._createDefaultTest(test);
      case 'sound_reproduction_letter': return this._createSoundReproductionTest(test);
      case 'sound_reproduction_word': return this._createSoundReproductionTest(test);
      case 'sound_reproduction_phrase': return this._createSoundReproductionTest(test);
      case 'spell_picture': return this._createSpellTest(test);
      case 'spell_repeat': return this._createSpellTest(test);
      default: return null;
    };
  };

  _createOptionsTest = (test) => (
    <SelectTest {...{
      test: test,
      onInputChange: this.props.onInputChange
    }} />
  );

  _createTextareaTest = (test) => (
    <TextareaTest {...{
      test: test,
      onInputChange: this.props.onInputChange
    }} />
  );

  _createDefaultTest = (test) => (
    <DefaultTest {...{
      test: test,
      onInputChange: this.props.onInputChange
    }} />
  );

  _createSoundReproductionTest = (test) => (
    <SoundReproductionTest {...{
      test: test,
      onInputChange: this.props.onInputChange
    }} />
  );

  _createSpellTest = (test) => (
    <SpellTest {...{
      test: test,
      onInputChange: this.props.onInputChange
    }} />
  );

}

export default Test;
