import React from 'react';
import ImageFile from "./ImageFile";
import DefaultTest from "./DefaultTest";

class TextareaTest extends DefaultTest {
    constructor(props) {
        super(props);
    }

    handleChange = (e) => {
        this.props.test.value = e.target.value;
        this.props.onInputChange(e, { newTest: this.props.test });
    };

    render() {
        var test = this.state.test;
        return (
            <>
                <div className="mb-3 row">
                    <label className="form-label">{test.title}{" "}<i>{test.description ? test.description : ""}</i></label>
                    <div className="col-sm-12">
                        <textarea
                            name={test.name}
                            value={test.value}
                            required={true}
                            onChange={this.handleChange}
                            className="form-control"
                            rows="3">
                        </textarea>
                    </div>
                </div>
            </>
        );
    }

}
export default TextareaTest;