import React from 'react';
import ImageFile from "./ImageFile";
import AudioFile from "./AudioFile";
import DefaultTest from "./DefaultTest";

class SoundReproductionTest extends DefaultTest {
    constructor(props) {
        super(props);
    }
    
    _getOptions = (test) => {
        if (test.type === 'sound_reproduction_letter') {
            return [
                {
                    title: "Пропускает",
                    value: "not_pronounce",
                    points: 1
                },
                {
                    title: "Искажает",
                    value: "distorts",
                    points: 2
                },
                {
                    title: "Правильно",
                    value: "norm",
                    points: 3
                }
            ];
        }
        if (test.type === 'sound_reproduction_word') {
            return [
                {
                    title: "Пропускает",
                    value: "not_pronounce",
                    points: 1
                },
                {
                    title: "Искажает",
                    value: "distorts",
                    points: 2
                },
                {
                    title: "Заменяет",
                    value: "replace",
                    points: 2
                },
                {
                    title: "Оглушает",
                    value: "deafen",
                    points: 2
                },
                {
                    title: "Смягчает",
                    value: "soften",
                    points: 2
                },
                {
                    title: "Правильно",
                    value: "norm",
                    points: 3
                },
            ];
        }
        if (test.type === 'sound_reproduction_phrase') {
            return [
                {
                    title: "Не автоматизирован",
                    value: "not_auto",
                    points: 1
                },
                {
                    title: "Автоматизирован",
                    value: "auto",
                    points: 3
                }
            ];
        }
    }

    getInline = (test) => {

        if (test.type === 'sound_reproduction_letter') {
            return false;
        }
        if (test.type === 'sound_reproduction_word') {
            return false;
        }
        if (test.type === 'sound_reproduction_phrase') {
            return true;
        }
    }

    getSubject = (test) => {

        if (test.type === 'sound_reproduction_letter') {
            return <><i className="text-muted">Произнести звук (изолированно)</i><br /><span className="fs-2"><span className="text-danger">[{test.letter}]</span></span></>;
        }
        if (test.type === 'sound_reproduction_word') {
            return <><i className="text-muted">Произнести слово</i><br /><span className="fs-2">{this.replaceLetter(test.subject, test.letter, test.letter_alias)}</span></>;
        }
        if (test.type === 'sound_reproduction_phrase') {
            return <><i className="text-muted">Произнести фразу</i><br /><span className="fs-2">{this.replaceLetter(test.subject, test.letter, test.letter_alias)}</span></>;
        }
    }

    replaceLetter = (str, letter, letter_alias) => {
        let result = [];
        let parts = str.split("[" + letter + "]");
        for (let i = 0; i < parts.length; i++) {
            result.push(parts[i]);
            result.push(<span key={i} className="text-danger">{letter_alias === undefined ? letter : letter_alias}</span>);
        }
        result.pop();
        return result;
    }

    render() {
        var test = this.state.test;
        return (
            <div className="mb-3 row align-items-center border-bottom">
                <div className="col-sm-2">
                    {test.image &&
                        <ImageFile image={test.image} title={test.title} />
                    }
                    {test.audio &&
                        <AudioFile audio={test.audio} title={test.title} />
                    }
                </div>
                <label htmlFor="" className="form-label col-sm-7">{this.getSubject(test)}{" "}<i>{test.description ? test.description : ""}</i>
                </label>
                <div className="col-sm-3">
                    {
                        this._renderInputRadio(test)
                    }
                </div>
            </div>
        );
    }
}

export default SoundReproductionTest;