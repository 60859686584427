import React, { Component } from "react";
import { Button, Modal } from 'react-bootstrap';
import './test.css';

class ImageFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      svg: "",
      show: false,
      tmbClass: "d-block mx-auto img-thumbnail border pointer"
    };
  }

  handleClose = () => { this.setState({ show: false }) };
  handleShow = () => { this.setState({ show: true }) };
  handleMouseOver = () => { this.setState({ tmbClass: "d-block mx-auto img-thumbnail border border-primary pointer" }) };
  handleMouseOut = () => { this.setState({ tmbClass: "d-block mx-auto img-thumbnail border pointer" }) };

  componentDidMount() {
    import(`../data/images/${this.props.image}.svg`).then(response => {
      this.setState({ svg: response.default });
    })
      .catch(e => {
        console.log(e);
      });
  }


  render() {
    return (
      <>
        <img
          src={this.state.svg}
          width="100"
          onClick={this.handleShow}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          className={this.state.tmbClass}
          alt={this.props.title}
        />
        <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="modal-xl">
          <Modal.Header>
            <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body><img src={this.state.svg} className="img-fluid mx-auto d-block border" alt={this.props.title} /></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Закрыть
          </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ImageFile;
