import React from 'react';
import ImageFile from "./ImageFile";
import DefaultTest from "./DefaultTest";

class SpellTest extends DefaultTest {
    constructor(props) {
        super(props);
    }

    handlePronunciationVariantChange = (e, spellGroupKey, wordKey) => {
        this.props.test.spellGroups[spellGroupKey].words[wordKey].pronunciationVariant = e.target.value;
        this.props.onInputChange(e, { newTest: this.props.test });
    };

    handleTypeOfDistortionChange = (e, spellGroupKey, wordKey) => {
        this.props.test.spellGroups[spellGroupKey].words[wordKey].typeOfDistortion = e.target.value;
        this.props.onInputChange(e, { newTest: this.props.test });
    };

    render() {
        var test = this.state.test;
        return (
            <>
                <h6>{test.title}</h6><hr />
                <div className="mb-3 row align-items-center">
                    <div className="col-sm-2 text-center">
                        {
                            this._renderInputRadio(test)
                        }
                    </div>
                    <div className="col-sm-10">
                        {test.spellGroups.map((spellGroup, spellGroupKey) => {
                            return (
                                <div key={spellGroupKey}>

                                    <div className="row mb-2  align-items-center">
                                        <div className="col-2 text-center">
                                            <span className="fs-5 text-success">{spellGroup.title}</span>
                                        </div>
                                        <div className="col-10">
                                            <div className="row">
                                                {spellGroup.words.map((word, wordKey) => {
                                                    return (
                                                        <div className="col-4 text-center" key={wordKey}>
                                                            <span className="fs-3">{word.title}</span>
                                                            <input onChange={(e) => this.handlePronunciationVariantChange(e, spellGroupKey, wordKey)} name="pronunciationVariant" className="form-control form-control-sm mb-2" placeholder="Вариант произношения" value={word.pronunciationVariant} />
                                                            <select onChange={(e) => this.handleTypeOfDistortionChange(e, spellGroupKey, wordKey)} name="typeOfDistortion" className="form-select form-select-sm mb-2" placeholder="Вид искажения" value={word.typeOfDistortion}>
                                                                {this._renderOptions(this._getTypeOfDistortion())}
                                                            </select>
                                                            {word.image && word.image !== "" &&
                                                                <ImageFile image={word.image} />
                                                            }
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }

    _getTypeOfDistortion = () => {
        return [
            {
                title: "Без искажения",
                value: "not_distortion"
            },
            {
                title: "Нарушение количества",
                value: "quantity_violation"
            },
            {
                title: "Нарушение последовательности",
                value: "sequence_violation"
            },
            {
                title: "Искажение отдельного слога",
                value: "distortion_single_syllable"
            },
            {
                title: "Антиципации",
                value: "anticipations"
            },
            {
                title: "Персеверации",
                value: "perseverations"
            },
            {
                title: "Контаминации",
                value: "contamination"
            }
        ];
    }

}
export default SpellTest;