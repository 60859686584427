import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="alert alert-light">
        <i>Звук:</i> <b>{label}</b><br />
        <i>Баллы:</i> {payload[0].value}<br />
        <i>Описание:</i> {payload[0].payload.description}
      </div>
    );
  }
  return null;
};

export default class ResultSoundReproductionChart extends PureComponent {

  render() {

    console.log(this.props.letters);

    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={this.props.letters}
          margin={{
            top: 5,
            right: 5,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="title" />
          <YAxis tickCount={4} type="number" domain={[0, 1, 2, 3]} />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="points" fill="#0d6efd" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
