import React, { Component } from "react";
import TestList from "../tests/TestList";
import { withRouter } from 'react-router-dom';
import PatientSurveyService from "../services/PatientSurveyService";
import { Link } from 'react-router-dom';
import Loader from "../loader/Loader";
import PointsProcessor from "./PointsProcessor";

class PatientSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      formData: {
        groups: []
      },
    };
  }

  getFormData = () => {
    if (this.props.isNewSurvey === true) {
      import(`../data/${this.props.match.params.surveyId}.json`).then(response => {
        const nextFormData = Object.assign({}, response.default);
        PointsProcessor.Process(nextFormData);
        this.setState({
          formData: nextFormData, loader: false
        });
      })
        .catch(e => {
          console.log(e);
        });

    } else {
      PatientSurveyService.get(this.props.match.params.surveyId)
        .then(response => {
          const nextFormData = Object.assign({}, response.data.data.survey_json);
          PointsProcessor.Process(nextFormData);
          this.setState({
            createdAt:response.data.data.created_at, formData: nextFormData, loader: false
          });
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  componentDidMount() {
    this.getFormData();
  }

  updateModel = () => {
    if (this.props.isNewSurvey === true) {
      PatientSurveyService.store(this.props.match.params.patientId,
        {
          survey_json: this.state.formData,
        }
      )
        .then(response => {
          this.props.history.push(`/patient/view/${this.props.match.params.patientId}`);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      PatientSurveyService.update(this.props.match.params.surveyId,
        {
          survey_json: this.state.formData,
        }
      )
        .then(response => {
          this.props.history.push(`/patient/view/${this.props.match.params.patientId}`);
        })
        .catch(e => {
          console.log(e);
        });
    }
  }

  deleteModel = () => {
    PatientSurveyService.destroy(this.props.match.params.surveyId)
      .then(response => {
        this.props.history.push(`/patient/view/${this.props.match.params.patientId}`);
      })
      .catch(e => {
        console.log(e);
      });
  };

  onInputChange = (e, state) => {

    console.log(state)
    const { newTest, groupName } = state;

    const nextFormData = Object.assign({}, this.state.formData);

    nextFormData.groups.forEach((group, groupKey) => {
      if (group.name === groupName) {
        group.subgroups.forEach((subgroup, subgroupKey) => {
          subgroup.tests.forEach((test, testKey) => {
            if (test.name === newTest.name) {
              nextFormData.groups[groupKey].subgroups[subgroupKey].tests[testKey] = newTest;
            }
          });
        });
      }
    });

    PointsProcessor.Process(nextFormData);
    this.setState({ formData: nextFormData });
  };

  render() {
    return (
      this.state.loader ?
        <Loader />
        :
        <div className="row">
          <div className="col-12">
            <form>
              <TestList
                createdAt={this.state.createdAt}
                formData={this.state.formData}
                onInputChange={this.onInputChange}
                isNewSurvey={this.props.isNewSurvey}
                patientId={this.props.match.params.patientId}
                surveyId={this.props.match.params.surveyId}
              />
            </form>
            <div className="row gy-3 mt-5 mb-5 justify-content-end">
              <div className="col-6 col-md-2 d-grid">
                <Link className="btn btn-outline-primary" to={`/patient/view/${this.props.match.params.patientId}`}>Отмена</Link>
              </div>
              {
                !this.props.isNewSurvey && <div className="col-6 col-md-2 d-grid">
                  <button className="btn btn-outline-danger" onClick={this.deleteModel}>Удалить</button>
                </div>
              }
              <div className="col-6 col-md-2 d-grid">
                <button type="submit" className="btn btn-primary" onClick={this.updateModel}>Сохранить</button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(PatientSurvey);
