import React, { Component } from "react";
import Test from "./Test";
import { withRouter } from 'react-router-dom';

class TestGroup extends Component {

  onInputChange = (e, state) => {

    const nextState = { ...state, groupName: this.props.group.name };
    this.props.onInputChange(e, nextState);
  };

  render() {
    const type = this.props.type;
    const group = this.props.group;
    const percent = group.perfomTestNumber / group.testNumber * 100;
    return (
      <div className="border p-3 mb-3">
        <div className="fs-5 mb-3">
          {group.title}
        </div>
        {group.subgroups.map((subgroup, key) => {
          if (type === "spell") {
            return (
              <>
                <div className="row">
                  {subgroup.title &&
                    <div className="col-12">
                      <h4>
                        {subgroup.title}
                      </h4>
                      <div className="fs-4 mb-5"><i>{subgroup.description}</i></div>
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-12">
                    {subgroup.tests.map((test) => {
                      return (
                        <Test
                          key={test.name}
                          test={test}
                          onInputChange={this.onInputChange}
                        />
                      );
                    })}
                  </div>
                </div>

              </>
            )
          }
          else {
            return (
              <div className={subgroup.title ? `border p-3 mb-3` : ``} key={key}>
                <div className="row">
                  {subgroup.title &&
                    <div className="col-12 col-md-2">
                      <h5>
                        {subgroup.title}
                      </h5>
                      <div className="pb-3"><i>{subgroup.description}</i></div>
                    </div>
                  }
                  <div className={subgroup.title ? `col-12 col-md-10 border-start` : `col-12 col-md-12 border-start`}>
                    {subgroup.tests.map((test) => {
                      return (
                        <Test
                          key={test.name}
                          test={test}
                          onInputChange={this.onInputChange}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            )
          }
        })}
        <div className="progress mt-2" style={{ height: 3 }}>
          <div className={`progress-bar ${percent === 100 ? 'bg-success' : 'bg-danger'}`} style={{ width: percent + '%' }} role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div className="row">
          <div className={`col-12 text-end ${percent === 100 ? 'text-success' : 'text-danger'}`}>
            <h3>{group.result ? Number(group.result).toFixed(1) : ''}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(TestGroup);
