import React from "react";
import { withRouter } from 'react-router-dom';
import PatientService from "../services/PatientService";
import Loader from "../loader/Loader";
import PatientSurveyList from "./PatientSurveyList";
import PatientSurvey from "./PatientSurvey";
import { Link } from 'react-router-dom';
import PatientHistory from "./PatientHistory";
import PatientResult from "./PatientResult";
import PatientCorrectionRoute from "./PatientCorrectionRoute";

class PatientView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      model: {
        id: null,
        first_name: "",
        middle_name: "",
        last_name: "",
        birthdate: ""
      }
    };
  }

  componentDidMount() {
    this.getModel();
  }

  getModel = () => {
    PatientService.get(this.props.match.params.patientId)
      .then(response => {
        this.setState({ model: response, loader: false });
      })
      .catch(e => {
        console.log(e);
      });
  };

  getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  renderAction = () => {
    if(this.props.withSurvey)
    {
      return <PatientSurvey isNewSurvey={this.props.isNewSurvey} />;
    }
    if(this.props.withResult)
    {
      return <PatientResult/>;
    }
    if(this.props.withCorrectionRoute)
    {
      return <PatientCorrectionRoute />;
    }
    return <PatientSurveyList patientId={this.props.match.params.patientId}/>;
  }


  render() {
    const model = this.state.model;
    return (
      this.state.loader ?
        <Loader />
        :
        <>
          <div className="row">
            <div className="col-6">
              <span className="fs-2">{model.last_name} {model.first_name} {model.middle_name}</span>
            </div>
            <div className="col-6 text-end">
              {!this.props.withSurvey &&
                <Link
                  to={`/patient/edit/${this.props.match.params.patientId}`}
                  className="btn btn-outline-primary btn-sm mr-1">
                  Редактировать
               </Link>}
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <span className="fs-5 text-muted">Полных лет:</span> <span className="fs-3">{this.getAge(model.birthdate)}</span>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-12">
              <div className="accordion" id="accordionPatient">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Сведения о семье
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionPatient">
                    <div className="accordion-body">
                      <PatientHistory
                        patientHistory={model.family_history_json}
                        isEdit={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      АНАМНЕЗ
                    </button>
                  </h2>
                  <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionPatient">
                    <div className="accordion-body">
                      <PatientHistory
                        patientHistory={model.medical_history_json}
                        isEdit={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {
                this.renderAction()
              }
            </div>
          </div>
        </>
    );
  }
}

export default withRouter(PatientView);