import React, { Component } from "react";
import stopSvg from '../data/audio/player/stop.svg';
import playSvg from '../data/audio/player/play.svg';
import './test.css';

class AudioFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      tmbClass: "d-block img-thumbnail border pointer"
    };
  }

  componentDidMount() {

    import(`../data/audio/${this.props.audio}.mp3`).then(response => {
      this.player.src = response.default;
      this.player.load();
    })
      .catch(e => {
        console.log(e);
      });

  }

  controlAudio = () => {
    if (this.state.playing) {
      this.player.pause();
      this.player.currentTime = 0.0;
      this.setState({playing: false})
    } else {
      this.player.play();
      this.setState({playing: true})
    }
  }

  handlerEnded = () => {this.setState({ playing: false })};
  handleMouseOver = () => { this.setState({ tmbClass: "d-block img-thumbnail border border-primary pointer" }) };
  handleMouseOut = () => { this.setState({ tmbClass: "d-block img-thumbnail border pointer" }) };

  render() {
    return (
      <>
        <img
          src={this.state.playing ? stopSvg : playSvg}
          width="100"
          onClick={this.controlAudio}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
          className={this.state.tmbClass}
          alt={this.props.title}
        />
        <audio ref={ref => (this.player = ref)} onEnded={this.handlerEnded} />
      </>
    );
  }
}

export default AudioFile;
